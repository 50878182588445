<template>
  <div class="certificate">
    <canvas ref="certificateCanvas" class='certificate-canvas'></canvas>
    <el-button type="primary" size="small" icon="el-icon-download" class="btn" @click="handleDownload" :loading="btnLoading">{{btnLoading ? '下载中' : '下载'}}</el-button>
  </div>
</template>

<script>
import timeFormat from '@/utils/timeFormat.js'

export default {
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      archiveId: '',
      btnLoading: false,
      splitName: false, // 是否将藏品名臣拆分为两行
      certificateInfo: {}
    }
  },
  mounted() {
    this.archiveId = this.$route.params.archiveId
    this.getCertificateInfo()
  },
  methods: {
    // 获取证书信息
    async getCertificateInfo() {
      try {
        const { data } = await this.$api.certificate.previewCertificate({ archiveId: this.archiveId })
        if (data.code === 0) {
          this.certificateInfo = data.data
          console.log(this.certificateInfo)
          this.fillContent()
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 绘制证书
    fillContent() {
      let canvas = this.$refs.certificateCanvas
      let ctx = canvas.getContext('2d')

      let img = new Image()
      img.src = require('./img/certificate-template.png')
      img.onload = () => {
        if (img.complete) {
          // 根据图像设定canvas长宽
          canvas.setAttribute('width', img.width)
          canvas.setAttribute('height', img.height)
          // 绘制图片
          ctx.drawImage(img, 0, 0, img.width, img.height)

          ctx.font = '38px 宋体'
          ctx.textBaseline = 'middle'
          ctx.textAlign = 'center'
          ctx.fillStyle = '#333333'
          // 证书编号
          this.certificateInfo.certificateNumber && ctx.fillText(this.certificateInfo.certificateNumber, 1107, 327)
          // 藏品名称
          ctx.font = '35px 宋体'
          this.handleName()
          if (this.splitName) {
            ctx.fillText(this.certificateInfo.nameArr[0], 893, 441)
            ctx.fillText(this.certificateInfo.nameArr[1], 893, 481)
          } else {
            ctx.fillText(this.certificateInfo.nameArr[0], 893, 460)
          }
          ctx.font = '38px 宋体'
          // 藏品材质
          ctx.fillText(this.certificateInfo.category, 1554, 460)
          // 参考年代
          ctx.fillText(this.certificateInfo.referenceYear, 893, 726)
          // 重量
          ctx.fillText(this.certificateInfo.weight, 1554, 727)
          // 日期
          ctx.fillText(timeFormat(new Date(this.certificateInfo.date), 'YYYY/MM/DD'), 1515, 2073)
          if (this.certificateInfo.size.length <= 30) {
            ctx.font = '38px 宋体'
            // 藏品规格
            ctx.fillText(this.certificateInfo.size, 1107, 592)
          } else {
            ctx.font = '23px 宋体'
            // 藏品规格
            ctx.fillText(this.certificateInfo.size, 1107, 592)
          }
          // 鉴定意见
          ctx.textAlign = 'left'
          ctx.font = '32px 宋体'
          this.handleBreakLine(ctx, 'opinion', 1331, 294, 920, 42)
          // 藏品备注
          ctx.font = '32px 宋体'
          this.handleBreakLine(ctx, 'remark', 1406, 1831, 1789, 60)
          // 身份唯一标识
          ctx.fillStyle = '#9B4E15'
          ctx.font = '800 66px 黑体'
          ctx.fillText('全球唯一身份证：' + this.certificateInfo.idCard, 2075, 460)
          // HashCode
          ctx.font = '400 32px 黑体'
          ctx.fillText(this.certificateInfo.nftHash, 2186, 768)
          // 区块链二维码
          let qrcodeImg = new Image()
          qrcodeImg.onload = () => {
            if (qrcodeImg.complete) {
              ctx.drawImage(qrcodeImg, 1968, 654, 178, 178)
            }
          }
          qrcodeImg.crossOrigin = 'anonymous'
          qrcodeImg.src = this.FileUrl + this.certificateInfo.plainCode
          // 藏品照片底部框
          let frame = new Image()
          frame.onload = () => {
            if (frame.complete) {
              this.handlePic(ctx, frame)
              this.handleMicroPic(ctx, frame)
              this.handleCiphers(ctx, frame)
            }
          }
          // frame.crossOrigin = 'anonymous'
          frame.src = require('./img/image-background.png')
        }
      }
    },
    // 处理藏品名称
    handleName() {
      if (this.certificateInfo.name.length <= 18) {
        this.$set(this.certificateInfo, 'nameArr', [this.certificateInfo.name])
      } else {
        this.splitName = true
        const index = Math.floor(this.certificateInfo.name.length / 2)
        this.$set(this.certificateInfo, 'nameArr', [this.certificateInfo.name.slice(0, index), this.certificateInfo.name.slice(index)])
      }
      console.log(this.certificateInfo)
    },
    // 处理多行文本
    /**
     * @param ctx 画布绘图环境
     * @param flag certificateInfo中分段换行的属性
     * @param width 每行最大宽度
     * @param x 绘制的起始x轴坐标
     * @param y 绘制的起始y轴坐标
     * @param gap 每行间隙
     */
    handleBreakLine(ctx, flag, width, x, y, gap) {
      if (this.certificateInfo[flag]) {
        let temp = this.certificateInfo[flag].split('\n')
        // 处理每个段落
        let textArr = []
        temp.forEach(item => {
          let arr = []
          let index = 0
          let currentIndex = 0
          while (item.length >= currentIndex + 1) {
            let tempStr = ''
            for (let i = index; ctx.measureText(tempStr).width <= width && item.length >= index + 1; i++) {
              if (i === 0) {
                tempStr += '    ' + item[i]
              } else {
                tempStr += item[i]
              }
              index++
            }
            arr.push(tempStr)
            currentIndex = index
          }
          textArr.push(...arr)
        })
        for (let i = 0; i < textArr.length; i++) {
          ctx.fillText(textArr[i], x, y + i * gap)
        }
      }
    },
    // 处理藏品照片
    handlePic(ctx, frame) {
      let imgArr = []
      imgArr.push(this.FileUrl + this.certificateInfo.picture.mainPicture.front)
      if (this.certificateInfo.picture.mainPicture.up) {
        imgArr.push(this.FileUrl + this.certificateInfo.picture.mainPicture.up)
      }
      imgArr.forEach((item, index) => {
        let img = new Image()
        img.onload = () => {
          if (img.complete) {
            if (imgArr.length === 1) {
              ctx.drawImage(frame, 2159, 1024, frame.width, frame.height)
              ctx.drawImage(img, 2177, 1036, frame.width * 0.9, frame.height * 0.9)
            } else {
              ctx.drawImage(frame, 1968 + (frame.width + 48) * index, 1024, frame.width, frame.height)
              ctx.drawImage(img, 1986 + (frame.width + 48) * index, 1036, frame.width * 0.9, frame.height * 0.9)
            }
          }
        }
        img.crossOrigin = 'anonymous'
        img.src = item
      })
    },
    // 微观照片
    handleMicroPic(ctx, frame) {
      let img = new Image()
      img.onload = () => {
        if (img.complete) {
          ctx.drawImage(frame, 2785, 1024, frame.width, frame.height)
          ctx.drawImage(img, 2803, 1036, frame.width * 0.9, frame.height * 0.9)
        }
      }
      img.crossOrigin = 'anonymous'
      img.src = this.FileUrl + this.certificateInfo.picture.microPicture[0].url
    },
    // 暗码截图
    handleCiphers(ctx, frame) {
      let imgArr = []
      this.certificateInfo.ciphers.forEach(item => {
        imgArr.push(item.picture)
      })
      const LEN = imgArr.length
      imgArr.forEach((item, index) => {
        let img = new Image()
        img.onload = () => {
          if (img.complete) {
            if (LEN === 1) {
              ctx.drawImage(frame, 2414, 1397, frame.width * 0.78, frame.height * 0.78)
              ctx.drawImage(img, 2428, 1406, frame.width * 0.78 * 0.9, frame.height * 0.78 * 0.9)
            } else if (LEN === 2) {
              ctx.drawImage(frame, 2270 + (frame.width * 0.78 + 25) * index, 1397, frame.width * 0.78, frame.height * 0.78)
              ctx.drawImage(img, 2284 + (frame.width * 0.78 + 25) * index, 1406, frame.width * 0.78 * 0.9, frame.height * 0.78 * 0.9)
            } else if (LEN === 3) {
              ctx.drawImage(frame, 2126 + (frame.width * 0.78 + 25) * index, 1397, frame.width * 0.78, frame.height * 0.78)
              ctx.drawImage(img, 2140 + (frame.width * 0.78 + 25) * index, 1406, frame.width * 0.78 * 0.9, frame.height * 0.78 * 0.9)
            } else if (LEN === 4) {
              ctx.drawImage(frame, 1982 + (frame.width * 0.78 + 25) * index, 1397, frame.width * 0.78, frame.height * 0.78)
              ctx.drawImage(img, 1996 + (frame.width * 0.78 + 25) * index, 1406, frame.width * 0.78 * 0.9, frame.height * 0.78 * 0.9)
            } else if (LEN === 5) {
              ctx.drawImage(frame, 1838 + (frame.width * 0.78 + 25) * index, 1397, frame.width * 0.78, frame.height * 0.78)
              ctx.drawImage(img, 1852 + (frame.width * 0.78 + 25) * index, 1406, frame.width * 0.78 * 0.9, frame.height * 0.78 * 0.9)
            }
          }
        }
        img.crossOrigin = 'anonymous'
        img.src = item + '?timestamp=' + new Date().getTime()
      })
    },
    handleDownload() {
      this.btnLoading = true
      console.log(this.btnLoading)
      setTimeout(() => {
        let canvas = this.$refs.certificateCanvas
        let imgSrc = canvas.toDataURL('image/png', 1.0)
        let imgName = `${this.certificateInfo.certificateNumber}.png`
        this.downloadImg(imgSrc, imgName)
        this.btnLoading = false
      }, 1000)
    },
    /**
    * @param imgSrc 文件链接可以是一个dataURL 也可以是一个 blob 对象。
    * @param imgName 下载后的文件名字。
    */
    downloadImg (imgSrc, imgName) {
      let elem = document.createElement('a')
      elem.setAttribute('href', imgSrc)
      elem.setAttribute('download', imgName)
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
    }
  }
}
</script>

<style lang="scss" scoped>
.certificate {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-canvas {
    width: calc(3508px / 2.8);
    height: calc(2481px / 2.8);
  }
  .btn {
    margin-top: 8px;
  }
}
</style>
